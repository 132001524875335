import React from "react";
import Small from "../../ui/shared/Small";

const KeyPoint = ({ keypoint, n, size }) => {
  return (
    <div className={`flex flex-col ${size}`}>
      <h3
        className="font-[600] leading-[36px] text-[24px]"
        style={{ color: keypoint.heading.color }}
      >
        {keypoint.heading.text}
      </h3>
      <Small style={{ color: keypoint.para.color }}>{keypoint.para.text}</Small>
    </div>
  );
};

export default KeyPoint;
