import React from "react";

const APIErrorPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-6xl font-extrabold text-red-600">Server Error</h1>
      <p className="mt-4 text-lg text-gray-700">
        Something went wrong while connecting to our servers.
      </p>
      <p className="mt-2 text-gray-500">
        Please try again later or contact support if the issue persists.
      </p>
      <div className="flex gap-2 mt-6">
        <button
          onClick={() => window.location.reload()}
          className="px-4 py-2 bg-green-500 text-white font-medium rounded-md hover:bg-blue-600 transition"
        >
          Retry
        </button>
        <button
          onClick={() => window.close()}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default APIErrorPage;
