import React from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";

const BulletPoint = (props) => {
  return (
    <li key={props.id} className="flex gap-2 items-center">
      <IoIosCheckmarkCircle color={props.iconColor} size={25} />{" "}
      {props.children}
    </li>
  );
};

export default BulletPoint;
