import React from "react";
import Small from "../../ui/shared/Small";

const ServiceCard = ({ service }) => {
  return (
    <div className="rounded-md w-[49%] sm:w-full ">
      <div className=" gap-2 flex flex-col bg-[#FCFAED] px-5 xl:px-8 sm:px-6 py-6 sm:py-6 pb-6 w-full h-full rounded-lg">
        <h2
          className="font-[600] text-[24px] leading-[36px]"
          style={{ color: service.heading.color }}
        >
          {service.heading.text}
        </h2>
        <Small style={{ color: service.para.color }}>{service.para.text}</Small>
      </div>
    </div>
  );
};

export default ServiceCard;
