import React from "react";

const Small = (props) => {
  return (
    <p
      style={props.style}
      className={`font-[400] text-[16px] leading-[24px] ${props.className}`}
    >
      {props.children}
    </p>
  );
};

export default Small;
