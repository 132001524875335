import React from "react";
import { THUMBNAIL_TYPE } from "../../template.enum";
import Small from "../../ui/shared/Small";

const ServiceCard = ({ service }) => {
  return (
    <div className="rounded-md w-[32%] xl:w-[32%] md:w-[48%] sm:w-full h-[400px] xl:h-[200px] md:h-[200px] sm:h-[350px] relative">
      <img
        className="w-full h-full absolute object-cover rounded-lg"
        alt="service"
        src={`${process.env.REACT_APP_ASSET_CDN_URL}/${service.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
      />
      <div className="flex flex-col justify-end bg-[rgba(0,0,0,0.65)] px-5 xl:px-8 sm:px-6 py-8 sm:py-6 pb-6 w-full h-full rounded-lg absolute z-10">
        <h2
          className="font-[600] text-[24px] leading-[36px]"
          style={{ color: service.heading.color }}
        >
          {service.heading.text}
        </h2>
        <Small style={{ color: service.para.color }}>{service.para.text}</Small>
      </div>
    </div>
  );
};

export default ServiceCard;
