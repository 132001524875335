import React, { useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";

const GenericModal = ({ isOpen, closeModal, modalContent }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={closeModal}
    >
      <div
        className="relative w-full max-w-[70%] sm:max-w-[90%] bg-white rounded-lg shadow-lg transform transition-all duration-300 ease-out"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold text-gray-800">
            {modalContent.title}
          </h2>
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-gray-600"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        {/* Scrollable Content */}
        <div className="p-4 max-h-[calc(100vh-200px)] overflow-y-auto">
          <p
            className="text-gray-600"
            dangerouslySetInnerHTML={{ __html: modalContent.content }}
          />
        </div>
      </div>
    </div>
  );
};

export default GenericModal;
