import React from "react";
import Para from "../ui/Para";

const KeyPoint = ({ keypoint }) => {
  return (
    <div className="flex flex-col w-[45%] md:w-[43%] items-center text-center sm:w-full">
      <h3
        className="font-semibold text-[24px] sm:text-[18px] md:text-[20px]"
        style={{ color: keypoint.heading.color }}
      >
        {keypoint.heading.text}
      </h3>
      <Para
        className="mt-5 sm:mt-2 font-light"
        style={{ color: keypoint.para.color }}
      >
        {keypoint.para.text}
      </Para>
    </div>
  );
};

export default KeyPoint;
