import React from "react";
import Heading from "../../../ui/shared/Heading";
import Para from "../../../ui/shared/Para";
import ServiceCard from "../../../elements/templateTwo/ServiceCard";

const Service = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex flex-col w-full items-center py-[80px] md:py-[30px] sm:py-[80px] 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="flex items-center sm:flex-col sm:gap-3">
          <Heading
            className="w-[38%] sm:w-full md:w-full"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="w-[60%] md:w-full sm:w-full"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
        </div>
        <div className="flex justify-between flex-wrap sm:flex-col gap-y-8 mt-16 sm:mt-10 w-full">
          {data.services.map((service, index) => (
            <ServiceCard
              key={service?.heading?.text + index}
              service={service}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
