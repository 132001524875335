import React from "react";
import About from "./About";
import Banner from "../../shared/Banner";
import Faqs from "./Faqs";
import Footer from "./Footer";
import Hero from "./Hero";
import KeyPoints from "./KeyPoints";
import Map from "../../shared/Map";
import Navbar from "./Navbar";
import SecondBanner from "./SecondBanner";
import Service from "./Sevice";
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import { WEB_TEMPLATE_SECTIONS } from "../../../template.enum";

const TemplateTwo = ({ fontFamily, data }) => {
  const renderSection = (section, index) => {
    switch (section.component) {
      case WEB_TEMPLATE_SECTIONS.HERO:
        return (
          <Hero
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.ABOUT:
        return (
          <About
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.SERVICE:
        return (
          <Service
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.BANNER:
        return (
          <Banner
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.KEYPOINT:
        return (
          <KeyPoints
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.SECONDBANNER:
        return (
          <SecondBanner
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.MAP:
        return (
          <Map
            key={section.component + index}
            data={section.data}
            id={section.hashId}
          />
        );
      case WEB_TEMPLATE_SECTIONS.FAQ:
        if (section.data.active) {
          return (
            <Faqs
              key={section.component + index}
              data={section.data}
              id={section.hashId}
            />
          );
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <>
      <ScrollToHashElement behavior="smooth" />
      <Navbar fontFamily={fontFamily} data={data.navbar} />
      <main className={`${fontFamily}`}>
        {data.sections &&
          data.sections.map((section, index) => renderSection(section, index))}
        <Footer data={data.footer} />
      </main>
    </>
  );
};

export default TemplateTwo;
