import React from "react";
import Heading from "../../../ui/shared/Heading";
import Para from "../../../ui/shared/Para";
import AboutImage from "../../../elements/templateTwo/AboutImage";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const About = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="mt-[320px] sm:mt-[260px] w-full py-[100px] sm:py-[20px] md:py-[80px] flex flex-col items-center 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="flex sm:flex-col justify-between gap-6 sm:gap-2">
          <Heading
            className="w-[40%] sm:w-full md:w-full"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="w-[58%] md:w-full sm:w-full"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
        </div>
        <div className="flex sm:hidden gap-2 gap-y-4 flex-wrap justify-between w-full mt-10">
          {data.images.map((image) => (
            <AboutImage
              key={image?.key}
              className="w-[24%]"
              imageHeight="h-[250px] xl:h-[200px] md:h-[200px] sm:h-[200px]"
              src={`${process.env.REACT_APP_ASSET_CDN_URL}/${image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
            />
          ))}
        </div>
      </div>

      <div className="sm:block hidden my-5 ml-5 mb-10">
        <Swiper
          modules={[Navigation, Mousewheel]}
          spaceBetween={300}
          slidesPerView={2}
          mousewheel={true}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false
          }}
          // pagination={{ clickable: true }}
          className="mySwiper w-full"
        >
          {data.images.map((image, index) => (
            <SwiperSlide key={index}>
              <AboutImage
                key={image.key}
                src={`${process.env.REACT_APP_ASSET_CDN_URL}/${image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default About;
