import React from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import BulletPoint from "../../../elements/BulletPoint";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import Button from "../../../ui/Button";

const Hero = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div
        className={`w-full py-[120px] sm:py-[20px] md:py-[50px] xl:py-[80px] flex justify-between sm:flex-col md:flex-col items-center gap-10 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]`}
      >
        <div className="w-[50%] sm:w-full md:w-full">
          <Heading
            className="font-[600] text-[48px] xl:text-[35px] md:text-[35px] w-[80%] sm:w-full"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            style={{ color: data.para.color }}
            className="mt-5 text-gray-500"
          >
            {data.para.text}
          </Para>
          <ul
            className="mt-10 sm:mt-5 font-medium flex flex-col gap-5 text-[18px] sm:text-[14px]"
            style={{ color: data.bullets.color }}
          >
            {data.bullets.data.map((bullet) => (
              <BulletPoint
                key={bullet}
                id={bullet}
                iconColor={
                  data.btn.bgColorDisplay
                    ? data.btn.bgColor
                    : data.bullets.color
                }
              >
                {bullet}
              </BulletPoint>
            ))}
          </ul>
          <Button
            link={data.btn.link}
            className="mt-10 hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out min-w-[250px] sm:w-full text-[17px] sm:text-[14px]"
            style={{
              color: data.btn.color,
              backgroundColor: data.btn.bgColorDisplay
                ? data.btn.bgColor
                : "transparent",
              border: !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
            }}
          >
            {data.btn.text}
          </Button>
        </div>
        <div className="w-[50%] sm:w-full md:w-full">
          <img
            className="rounded-lg h-[420px] sm:h-[250px] w-full object-cover"
            alt="hero"
            style={{ objectPosition: "top" }}
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
