import "./App.css";
import TemplateOne from "./components/templates/templateOne/TemplateOne";
import TemplateTwo from "./components/templates/templateTwo/TemplateTwo";
import TEMPLATE_TYPES from "./template.enum";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import axios from "axios";
import NoRecordFound from "./components/NoRecordFound";
import FullScreenLoader from "./components/FullScreenLoader";
import APIErrorPage from "./components/APIErrorPage";
import TemplateThree from "./components/templates/templateThree/TemplateThree";
import TemplateFour from "./components/templates/templateFour/TemplateFour";

function App() {
  const [data, setData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [showAPIError, setShowAPIError] = useState(false);
  const font = "font-golos-text";
  const font2 = "font-poppins";
  const font3 = "font-cormorant-garamond";

  useEffect(() => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const queryParameters = new URLSearchParams(window.location.search);
    const domain = queryParameters.get("domain");
    const subDomain = queryParameters.get("subDomain");
    const templateId = queryParameters.get("templateId");
    fetchWebsiteTemplate(baseUrl, domain, subDomain, templateId);
  }, []);

  const fetchWebsiteTemplate = async (
    baseUrl,
    domain,
    subDomain,
    templateId
  ) => {
    setLoader(true);
    const params = {
      domain: domain ? domain : window.location.hostname,
      subDomain
    };
    try {
      let website;
      if (templateId) {
        website = await axios
          .get(`${baseUrl}/customer/website/${templateId}`)
          .then((response) => response.data);
      } else {
        website = await axios
          .get(`${baseUrl}/customer/website`, { params })
          .then((response) => response.data);
      }
      document.title = website?.data?.customer?.companyName || "Website";
      setData(website.data);
    } catch (error) {
      console.error(error);
      setShowAPIError(true);
    } finally {
      setLoader(false);
    }
  };

  const renderTemplate = (data) => {
    switch (data.templateType) {
      case TEMPLATE_TYPES.TEMPLATE_1:
        return <TemplateOne fontFamily={font} data={data.data} />;
      case TEMPLATE_TYPES.TEMPLATE_2:
        return <TemplateTwo fontFamily={font2} data={data.data} />;
      case TEMPLATE_TYPES.TEMPLATE_3:
        return <TemplateThree fontFamily={font2} data={data.data} />;
      case TEMPLATE_TYPES.TEMPLATE_4:
        return (
          <TemplateFour
            fontFamily={font2}
            fontFamily2={font3}
            data={data.data}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Router>
        <div className="App">
          <Routes>
            <Route
              path="/"
              element={
                loader ? (
                  <FullScreenLoader />
                ) : (
                  <>
                    {data
                      ? renderTemplate(data)
                      : !showAPIError && <NoRecordFound />}
                  </>
                )
              }
            />
            <Route path="/api-error" element={<APIErrorPage />} />
          </Routes>
        </div>
      </Router>

      {showAPIError && <APIErrorPage />}
    </>
  );
}

export default App;
