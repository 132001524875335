import React from "react";
import Heading from "../../../ui/shared/Heading";
import Button from "../../../ui/Button";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import Small from "../../../ui/shared/Small";

const SecondBanner = ({ data, id }) => {
  return (
    <section
      id={id}
      className="relative"
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex items-center sm:flex-col md:flex-col gap-10 sm:gap-5 justify-between w-full 2xl:max-w-[1250px] mx-auto xl:px-[50px] md:px-[40px]">
        {/* Image container */}
        <div className="self-stretch w-[50%] md:w-full sm:w-full">
          <img
            className="3xl:w-[100%] w-[50%] sm:w-[100%] sm:static 3xl:static absolute object-cover h-full sm:h-auto top-0 left-0"
            alt="hero"
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
        </div>

        {/* Content container */}
        <div className="flex py-24 sm:py-5 sm:px-[20px] flex-col flex-1 w-[50%] md:w-full sm:w-full">
          <Heading className="" style={{ color: data.heading.color }}>
            {data.heading.text}
          </Heading>
          <Small
            className="mt-6 xl:mt-6 sm:mt-5"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Small>
          <Button
            link={data.btn.link}
            className="rounded-lg mt-10 hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out min-w-[200px] sm:w-full text-[15px] sm:text-[14px]"
            style={{
              color: data.btn.color,
              backgroundColor: data.btn.bgColorDisplay
                ? data.btn.bgColor
                : "transparent",
              border: !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
            }}
          >
            {data.btn.text}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SecondBanner;
