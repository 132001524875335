import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import Para from "../ui/Para";

const FAQ = (props) => {
  return (
    <div
      key={props.i}
      className={`border-b border-zinc-300 w-full py-[40px] sm:py-[20px] ${
        props.i === 0 ? "border-t" : ""
      }`}
    >
      <div className="head flex justify-between items-center">
        <h4
          className="font-medium text-[22px] xl:text-[20px] md:text-[20px] sm:text-[16px]"
          style={{ color: props.qColor }}
        >
          {props.q}
        </h4>
        {props.active ? (
          <FaMinus
            className="text-gray-400 cursor-pointer"
            onClick={props.onClose}
            size={16}
          />
        ) : (
          <FaPlus
            className="text-gray-400 cursor-pointer"
            onClick={() => {
              props.handleActive(props.i);
            }}
            size={16}
          />
        )}
      </div>
      <div className={`body ${!props.active ? "hidden" : ""}`}>
        <Para style={{ color: props.aColor }} className="mt-3">
          {props.a}
        </Para>
      </div>
    </div>
  );
};

export default FAQ;
