import React from "react";
import Heading from "../../../ui/templateFour/Heading";
import KeyPoint from "../../../elements/shared/KeyPoint";
import Small from "../../../ui/shared/Small";

const KeyPoints = ({ data, id, fontFamily2 }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="py-[100px] sm:py-[20px] sm:pb-[50px] md:py-[50px] flex flex-col gap-10 w-full 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="w-[100%] flex flex-col items-center">
          <Heading
            className={`text-center sm:text-left ${fontFamily2}`}
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Small
            className="mt-5 text-center w-[70%] sm:w-full sm:text-left"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Small>
        </div>
        <div className="mt-6 flex w-[100%] gap-4 xl:gap-20 md:gap-16 sm:gap-5 flex-wrap justify-between">
          {data.keyPoints.map((keypoint, index) => (
            <KeyPoint
              key={keypoint?.heading?.text + index}
              size="w-[24%] sm:w-full"
              keypoint={keypoint}
              n={index + 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyPoints;
