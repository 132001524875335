import React from "react";
import BulletPoint from "../../../elements/BulletPoint";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import Button from "../../../ui/Button";

const Hero = ({ data, id, fontFamily2 }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div
        className={`w-full py-[100px] pb-[40px] sm:py-[20px] sm:pb-[60px] md:py-[50px] xl:py-[80px] flex-col items-center justify-center sm:flex-col md:flex-col gap-10 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]`}
      >
        <div className="w-[100%]">
          <h1
            className={`font-[600] leading-[67.82px] sm:leading-[48.44px] text-[56px] sm:text-[40px] text-center sm:text-left ${fontFamily2}`}
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </h1>
        </div>
        <div className="w-[100%] flex sm:flex-col items-center mt-10 sm:mt-12 gap-6">
          <p
            style={{ color: data.para.color }}
            className="sm:hidden text-[21px] leading-[31.5px] sm:text-[12px] sm:leading-[18px] w-[35%]"
          >
            {data.para.text}
          </p>
          <img
            className="object-cover w-[360px] h-[455px]"
            alt="hero"
            style={{
              objectPosition: "top",
              borderRadius: "227.5px 227.5px 0 0"
            }}
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
          <div className="flex flex-col ms-6 sm:w-full sm:ms-0">
            <ul
              className="mt-6 sm:mt-5 flex flex-col gap-6 sm:gap-3 text-[18px] sm:text-[14px] sm:leading-[21px]"
              style={{ color: data.bullets.color }}
            >
              {data.bullets.data.map((bullet) => (
                <BulletPoint
                  key={bullet}
                  iconColor={
                    data.btn.bgColorDisplay
                      ? data.btn.bgColor
                      : data.bullets.color
                  }
                >
                  {bullet}
                </BulletPoint>
              ))}
            </ul>
            <Button
              link={data.btn.link}
              className="mt-10 sm:mt-6 hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out min-w-[150px] sm:w-full text-[17px] sm:text-[14px] rounded-xl"
              style={{
                color: data.btn.color,
                backgroundColor: data.btn.bgColorDisplay
                  ? data.btn.bgColor
                  : "transparent",
                border:
                  !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
              }}
            >
              {data.btn.text}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
