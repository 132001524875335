import React from "react";
import BulletPoint from "../../../elements/BulletPoint";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import Button from "../../../ui/Button";
import Small from "../../../ui/shared/Small";

const Hero = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div
        className={`w-full py-[100px] pt-[230px] sm:py-[20px] sm:pt-[180px] md:py-[50px] xl:py-[80px] flex-col items-center justify-center sm:flex-col md:flex-col gap-10 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]`}
      >
        <div className="w-[100%] flex flex-col items-center sm:items-start">
          <h1
            className="font-[600] leading-[96px] sm:leading-[60px] text-[64px] sm:text-[40px] text-center sm:text-left"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </h1>
          <Small
            style={{ color: data.para.color }}
            className="mt-3 sm:text-[12px] sm:leading-[18px] text-center sm:text-left"
          >
            {data.para.text}
          </Small>
          <ul
            className="mt-6 sm:mt-5 font-medium flex sm:flex-col gap-10 sm:gap-3 text-[18px] sm:text-[14px] sm:leading-[21px]"
            style={{ color: data.bullets.color }}
          >
            {data.bullets.data.map((bullet) => (
              <BulletPoint
                key={bullet}
                iconColor={
                  data.btn.bgColorDisplay
                    ? data.btn.bgColor
                    : data.bullets.color
                }
              >
                {bullet}
              </BulletPoint>
            ))}
          </ul>
          <Button
            link={data.btn.link}
            className="mt-10 sm:mt-6 hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out min-w-[250px] sm:w-full text-[17px] sm:text-[14px] rounded-xl"
            style={{
              color: data.btn.color,
              backgroundColor: data.btn.bgColorDisplay
                ? data.btn.bgColor
                : "transparent",
              border: !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
            }}
          >
            {data.btn.text}
          </Button>
        </div>
        <div className="w-[100%] mt-16 sm:mt-12 mb-[-420px] sm:mb-[-250px]">
          <img
            className="rounded-xl h-[512px] sm:h-[360px] w-full object-cover"
            alt="hero"
            style={{ objectPosition: "top" }}
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
