import React from "react";
import Heading from "../../../ui/shared/Heading";
import KeyPoint from "../../../elements/shared/KeyPoint";
import Small from "../../../ui/shared/Small";

const KeyPoints = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="py-[50px] pb-[100px] sm:py-[20px] sm:pb-[50px] md:py-[50px] flex sm:flex-col gap-10 w-full 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="w-[45%] sm:w-[100%]">
          <Heading className="" style={{ color: data.heading.color }}>
            {data.heading.text}
          </Heading>
          <Small className="mt-5" style={{ color: data.para.color }}>
            {data.para.text}
          </Small>
        </div>
        <div className="flex w-[55%] sm:w-[100%] gap-10 xl:gap-20 md:gap-16 sm:gap-5 flex-wrap justify-between">
          {data.keyPoints.map((keypoint, index) => (
            <KeyPoint
              key={keypoint?.heading?.text + index}
              size="w-[45%] md:w-[43%] sm:w-full"
              keypoint={keypoint}
              n={index + 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyPoints;
