import React, { useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaEnvelope
} from "react-icons/fa";
import Button from "../../../ui/Button";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import NavLink from "../../../elements/NavLink";
import { Link } from "react-router-dom";
import GenericModal from "../../GenericModal";

const Footer = ({ data }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const handleModal = (title, content) => {
    setModalContent({ title, content });
    toggleModal();
  };

  return (
    <footer
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex sm:flex-col sm:gap-10 md:gap-10 justify-between py-16 sm:py-10 md:py-16 flex-wrap 2xl:max-w-[1250px] xl:px-[50px] 3xl:max-w-[1400px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="w-[45%] sm:w-full">
          {data?.logo?.type?._id === "image" ? (
            <img
              className="rounded-lg object-cover w-[150px] h-auto sm:w-[150px]"
              alt="navbar logo"
              src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.logo?.image?.key}/${THUMBNAIL_TYPE.COMPANY_LOGO}`}
            />
          ) : (
            <h5
              className="font-semibold text-[42px] sm:text-[30px]"
              style={{ color: data.logo.color }}
            >
              {data.logo.text}
            </h5>
          )}
          <p
            className="mt-6 text-[18px] sm:text-[16px]"
            style={{ color: data.tag_line.color }}
          >
            {data.tag_line.text}
          </p>
          <div className="flex gap-5 mt-5 sm:mt-5">
            {data.facebook.link && (
              <a
                target="_blank"
                rel="noreferrer"
                href={data.facebook.link}
                style={{ color: data.facebook.color }}
              >
                <FaFacebookF size={24} />
              </a>
            )}
            {data.instagram.link && (
              <a
                target="_blank"
                rel="noreferrer"
                href={data.instagram.link}
                style={{ color: data.instagram.color }}
              >
                <FaInstagram size={24} />
              </a>
            )}
            {data.youtube.link && (
              <a
                target="_blank"
                rel="noreferrer"
                href={data.youtube.link}
                style={{ color: data.youtube.color }}
              >
                <FaYoutube size={24} />
              </a>
            )}
          </div>
        </div>
        <div>
          <h5
            className="font-medium text-[20px]"
            style={{ color: data.footer_links.quick_links.color }}
          >
            {data.footer_links.quick_links.text}
          </h5>
          <ul
            className="mt-5 flex flex-col gap-5 font-medium"
            style={{ color: data.footer_links.quick_links.color }}
          >
            {data.footer_links.quick_links.links &&
              data.footer_links.quick_links.links.map((link, index) => (
                <NavLink key={link?.text + index} link={link?.link?.hashId}>
                  {link.text}
                </NavLink>
              ))}
          </ul>
        </div>
        {/* <div>
          <h5
            className="font-medium text-[20px]"
            style={{ color: data.footer_links.social_link.color }}
          >
            {data.footer_links.social_link.text}
          </h5>
          <ul
            className="mt-5 flex flex-col gap-5 font-medium"
            style={{ color: data.footer_links.social_link.color }}
          >
            {data.facebook.link && (
              <li>
                <a
                  className="flex gap-2"
                  target="_blank"
                  rel="noreferrer"
                  href={data.facebook.link}
                >
                  <FaFacebookF size={24} color={data.facebook.color} />
                  Facebook
                </a>
              </li>
            )}
            {data.instagram.link && (
              <li>
                <a
                  className="flex gap-2"
                  target="_blank"
                  rel="noreferrer"
                  href={data.instagram.link}
                >
                  <FaInstagram size={24} color={data.instagram.color} />
                  Instagram
                </a>
              </li>
            )}
            {data.youtube.link && (
              <li>
                <a
                  className="flex gap-2"
                  target="_blank"
                  rel="noreferrer"
                  href={data.youtube.link}
                >
                  <FaYoutube size={24} color={data.youtube.color} />
                  Youtube
                </a>
              </li>
            )}
          </ul>
        </div> */}
        <div>
          <h5
            className="font-medium text-[20px]"
            style={{ color: data.contact_card.name.color }}
          >
            {data.contact_card.text}
          </h5>
          <div className="mt-5">
            <div className="flex gap-3 items-center">
              <div className="w-[80px] h-[80px]">
                <img
                  className="rounded-full w-full h-full object-cover object-top"
                  src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.contact_card.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
                  alt="person"
                />
              </div>
              <div>
                <h3
                  className="font-semibold text-[18px]"
                  style={{ color: data.contact_card.name.color }}
                >
                  {data.contact_card.name.text}
                </h3>
                <p
                  className="text-zinc-400 text-[16px]"
                  style={{ color: data.contact_card.title.color }}
                >
                  {data.contact_card.title.text}
                </p>
                {data?.contact_card?.email?.text && (
                  <p
                    style={{ color: data.contact_card.email.color }}
                    className="flex items-center gap-2 text-[14px]"
                  >
                    <FaEnvelope />
                    <a href={`mailto:${data.contact_card.email.text}`}>
                      {data.contact_card.email.text}
                    </a>
                  </p>
                )}
              </div>
            </div>
            <Button
              link={data.contact_card.btn.link}
              className="mt-6 min-w-[200px] xl:min-w-[250px] sm:min-w-full sm:w-full rounded-lg text-[14px] hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out"
              style={{
                color: data.contact_card.btn.color,
                backgroundColor: data.contact_card.btn.bgColorDisplay
                  ? data.contact_card.btn.bgColor
                  : "transparent",
                border:
                  !data.contact_card.btn.bgColorDisplay &&
                  `1px solid ${data.contact_card.btn.color}`
              }}
            >
              {data.contact_card.btn.text}
            </Button>
          </div>
        </div>
      </div>
      <div className="flex sm:flex-col sm:gap-5 items-center justify-between py-5 sm:py-5 2xl:max-w-[1250px] xl:px-[50px] 3xl:max-w-[1400px] mx-auto border-t border-t-[rgba(255,255,255,0.1)]">
        <p
          style={{ color: data.footer_links.pp_links.color }}
          className="sm:text-[14px]"
        >
          Copyright © 2025 • A & M Cockpit.
        </p>
        <div>
          <ul
            className="flex gap-5 font-normal"
            style={{ color: data.footer_links.pp_links.color }}
          >
            {data.footer_links.pp_links.links &&
              data.footer_links.pp_links.links.map((link, index) => {
                if (link.link) {
                  return (
                    <li key={link?.text + index} className="cursor-pointer">
                      <Link to={link.link} target="_blank">
                        {link.text}
                      </Link>
                    </li>
                  );
                } else {
                  return (
                    <li
                      key={link?.text + index}
                      onClick={() => handleModal(link.text, link.content)}
                      className="cursor-pointer"
                    >
                      {link.text}
                    </li>
                  );
                }
              })}
          </ul>
        </div>
      </div>

      {showModal && (
        <GenericModal
          isOpen={showModal}
          closeModal={toggleModal}
          modalContent={modalContent}
        />
      )}
    </footer>
  );
};

export default Footer;
