import React from "react";
import { FaPlus, FaMinus } from "react-icons/fa6";
import Small from "../../ui/shared/Small";

const FAQ = (props) => {
  return (
    <div
      key={props.i}
      className={`${
        props.i !== props.last && "border-b"
      } border-zinc-300 w-full py-[40px] sm:py-[20px]`}
    >
      <div className="head flex justify-between items-center">
        <h4
          className="font-medium text-[24px] sm:text-[18px] leading-[36px] sm:leading-[27px]"
          style={{ color: props.qColor }}
        >
          {props.q}
        </h4>
        {props.active ? (
          <button
            className="border border-red-500 rounded-full p-1"
            onClick={props.onClose}
          >
            <FaMinus className="text-red-500" size={16} />
          </button>
        ) : (
          <button
            className="border border-black rounded-full p-1"
            onClick={() => {
              props.handleActive(props.i);
            }}
          >
            <FaPlus className="text-black" size={16} />
          </button>
        )}
      </div>
      <div className={`body ${!props.active ? "hidden" : ""}`}>
        <Small style={{ color: props.aColor }} className="mt-3">
          {props.a}
        </Small>
      </div>
    </div>
  );
};

export default FAQ;
