import React from "react";
import Heading from "../../../ui/templateFour/Heading";
import Para from "../../../ui/shared/Para";
import ServiceCard from "../../../elements/templateFour/ServiceCard";

const Service = ({ data, id, fontFamily2 }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex sm:flex-col w-full items-center py-[80px] md:py-[30px] sm:py-[40px] 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px] justify-between sm:gap-10">
        <div className="flex flex-wrap sm:flex-col gap-3 w-[50%] sm:w-full sm:order-2">
          {data.services.map((service, index) => (
            <ServiceCard
              key={service?.heading?.text + index}
              service={service}
            />
          ))}
        </div>
        <div className="flex flex-col gap-3 w-[45%] sm:w-full sm:order-1">
          <Heading
            className={`sm:w-full md:w-full ${fontFamily2}`}
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="md:w-full sm:w-full"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
        </div>
      </div>
    </section>
  );
};

export default Service;
