import React from "react";

const NavLink = (props) => {
  return (
    <li className="cursor-pointer" onClick={props.onClick}>
      <a href={`#${props.link}`}>{props.children}</a>
    </li>
  );
};

export default NavLink;
