import React from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import Button from "../../../ui/Button";
import { THUMBNAIL_TYPE } from "../../../template.enum";

const SecondBanner = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="py-[120px] md:py-[70px] sm:py-[20px] flex items-center sm:flex-col md:flex-col gap-10 sm:gap-5 justify-between w-full 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        {/* Image container */}
        <div className="flex items-center justify-center w-[50%] md:w-full sm:w-full">
          <img
            className="rounded-lg w-full object-cover h-[500px] sm:h-auto"
            alt="hero"
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
        </div>

        {/* Content container */}
        <div className="bg-white flex flex-col p-12 sm:p-6 flex-1 rounded-lg shadow-lg w-[50%] md:w-full sm:w-full">
          <Heading
            className="text-[32px] xl:text-[28px] sm:text-[18px] font-[600]"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <hr className="w-[100px] border-gray-300 mt-5" />
          <Para
            className="mt-6 xl:mt-6 sm:mt-5 text-gray-800"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
          <Button
            link={data.btn.link}
            className="rounded mt-5 hover:bg-zinc-200 hover:text-black transition-colors duration-1000 ease-in-out min-w-[200px] sm:w-full text-[15px] sm:text-[14px]"
            style={{
              color: data.btn.color,
              backgroundColor: data.btn.bgColorDisplay
                ? data.btn.bgColor
                : "transparent",
              border: !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
            }}
          >
            {data.btn.text}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SecondBanner;
