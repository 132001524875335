import React from "react";

const Para = (props) => {
  return (
    <p
      style={props.style}
      className={`font-light text-[18px] xl:text-[16px] sm:text-[14px] leading-relaxed ${props.className}`}
    >
      {props.children}
    </p>
  );
};

export default Para;
