import React, { useState } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import NavLink from "../../../elements/NavLink";
import { THUMBNAIL_TYPE } from "../../../template.enum";

const Navbar = ({ fontFamily, data }) => {
  const [open, setOpen] = useState(false);

  return (
    <header
      className={`${fontFamily} w-full relative`}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex items-center justify-between py-6 sm:py-3 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        {/* Desktop Navigation */}
        <nav className="flex justify-between items-center w-full">
          {/* Logo Section */}
          <div className="flex-shrink-0 mr-6 sm:mr-0">
            {data?.logo?.type?._id === "image" ? (
              <img
                className="rounded-lg h-auto w-[150px]"
                alt="navbar logo"
                src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.logo?.image?.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`}
              />
            ) : (
              <h5
                className="text-[20px] font-semibold w-fit whitespace-nowrap"
                style={{ color: data.logo.color }}
              >
                {data.logo.text}
              </h5>
            )}
          </div>

          {/* Navigation Links */}
          <ul
            className="flex gap-6 font-medium text-[17px] sm:hidden md:hidden"
            style={{ color: data.links.color }}
          >
            {data.links.links.map((link, index) => (
              <NavLink key={index} link={link?.link?.hashId}>
                {link.text}
              </NavLink>
            ))}
          </ul>

          {/* Phone & Address */}
          <div className="flex gap-6 items-center font-medium text-[15px] sm:hidden md:hidden">
            <div
              className="flex gap-2 items-center"
              style={{ color: data.phone.color }}
            >
              <FaPhoneAlt size={20} />
              <a href={`tel:${data.phone.text}`}>{data.phone.text}</a>
            </div>
            <div
              className="flex gap-2 items-center"
              style={{ color: data.address.color }}
            >
              <FaLocationDot size={20} />
              <p>{data.address.text}</p>
            </div>
          </div>

          {/* Mobile Menu Button */}
          <div
            style={{ color: data.links.color }}
            className="hidden sm:block md:block cursor-pointer"
          >
            <IoIosMenu onClick={() => setOpen(true)} size={28} />
          </div>
        </nav>

        {/* Mobile Navigation */}
        <div
          style={{
            backgroundColor: data.bgColorDisplay ? data.bgColor : "white"
          }}
          className={`z-50 fixed top-0 left-0 w-full h-full p-5 flex flex-col items-center justify-between transform transition-transform duration-500 ease-in-out ${
            open ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"
          }`}
        >
          {/* Close Button */}
          <IoMdClose
            style={{ color: data.links.color }}
            onClick={() => setOpen(false)}
            size={28}
            className="absolute right-5 top-5 cursor-pointer"
          />

          {/* Logo */}
          <div className="mb-6">
            {data?.logo?.type?._id === "image" ? (
              <img
                className="rounded-lg w-[100px]"
                alt="navbar logo"
                src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.logo?.image?.key}/${THUMBNAIL_TYPE.ANSWER_OPTION_ICON}`}
              />
            ) : (
              <h5
                className="text-[22px] font-semibold w-fit"
                style={{ color: data.logo.color }}
              >
                {data.logo.text}
              </h5>
            )}
          </div>

          {/* Mobile Links */}
          <ul
            className="flex flex-col justify-center items-center gap-5 font-semibold text-[16px]"
            style={{ color: data.links.color }}
          >
            {data.links.links.map((link, index) => (
              <NavLink
                onClick={() => setOpen(false)}
                key={index}
                link={link?.link?.hashId}
              >
                {link.text}
              </NavLink>
            ))}
          </ul>

          {/* Mobile Phone & Address */}
          <div className="flex flex-col gap-4 items-center font-semibold text-[14px] mt-6">
            <div
              className="flex gap-2 items-center"
              style={{ color: data.phone.color }}
            >
              <FaPhoneAlt size={20} />
              <a href={`tel:${data.phone.text}`}>{data.phone.text}</a>
            </div>
            <div
              className="flex gap-2 items-center"
              style={{ color: data.address.color }}
            >
              <FaLocationDot size={20} />
              <p>{data.address.text}</p>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
