import React from "react";
import { THUMBNAIL_TYPE } from "../template.enum";

const ServiceCard = ({ service }) => {
  return (
    <div className="flex flex-col bg-[#F2F2F2] rounded-md w-[32%] xl:w-[32%] md:w-[48%] sm:w-full">
      <img
        className="w-full h-[300px] xl:h-[200px] md:h-[200px] sm:h-[200px] object-cover rounded-tl-md rounded-tr-md"
        alt="service"
        src={`${process.env.REACT_APP_ASSET_CDN_URL}/${service.image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
      />
      <div className="flex flex-col px-12 xl:px-8 sm:px-6 py-8 sm:py-6 pb-12 gap-5">
        <h2
          className="font-medium text-[24px] sm:text-[20px]"
          style={{ color: service.heading.color }}
        >
          {service.heading.text}
        </h2>
        <p
          className="font-light text-[16px] sm:text-[14px]"
          style={{ color: service.para.color }}
        >
          {service.para.text}
        </p>
      </div>
    </div>
  );
};

export default ServiceCard;
