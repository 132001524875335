import React from "react";

const NoRecordFound = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 text-center">
        <div className="text-blue-500 text-6xl mb-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-16 h-16 mx-auto"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9.75 9.75h.008v.008H9.75v-.008zM14.25 9.75h.008v.008h-.008v-.008zM12 21a9 9 0 100-18 9 9 0 000 18zm0-9v.01m-3.2-3.19a3.2 3.2 0 116.4 0 3.2 3.2 0 01-6.4 0z"
            />
          </svg>
        </div>
        <h1 className="text-xl font-bold text-gray-800 mb-2">
          No Record Found
        </h1>
        <p className="text-gray-600 mb-4">
          Please create any template first for this customer
        </p>
        <button
          onClick={() => window.close()}
          className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default NoRecordFound;
