import React from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import AboutImage from "../../../elements/AboutImage";
import { THUMBNAIL_TYPE } from "../../../template.enum";

const About = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="w-full py-[100px] sm:py-[20px] md:py-[80px] flex flex-col items-center 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <Heading
          className="text-center w-[85%] sm:w-full md:w-full font-semibold text-[48px] xl:text-[35px] md:text-[35px]"
          style={{ color: data.heading.color }}
        >
          {data.heading.text}
        </Heading>
        <Para
          className="mt-10 sm:mt-5 text-center w-[65%] md:w-full text-gray-500 sm:w-full text-[20px]"
          style={{ color: data.para.color }}
        >
          {data.para.text}
        </Para>
        <div className="flex sm:flex-col gap-2 gap-y-4 mt-16 sm:mt-10 flex-wrap justify-between w-full">
          {data.images.map((image) => (
            <AboutImage
              key={image?.key}
              src={`${process.env.REACT_APP_ASSET_CDN_URL}/${image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
