import React from "react";
import Heading from "../../../ui/templateFour/Heading";
import Para from "../../../ui/shared/Para";
import { THUMBNAIL_TYPE } from "../../../template.enum";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const About = ({ data, id, fontFamily2 }) => {
  return (
    <section
      id={id}
      className="relative"
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="w-full flex sm:flex-col items-center 2xl:max-w-[1250px] xl:px-[50px] mx-auto  md:px-[40px] justify-between sm:py-[40px] sm:gap-10">
        <div className="flex flex-col justify-between gap-6 sm:gap-2 w-[43%] sm:w-full py-[200px] sm:py-0 md:py-[80px] sm:px-[20px]">
          <Heading
            className={`sm:w-full md:w-full ${fontFamily2}`}
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="md:w-full sm:w-full"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
        </div>
        <div className="flex gap-2 gap-y-4 flex-wrap justify-between w-[50%] sm:w-full">
          <img
            alt="Random"
            className="absolute 3xl:static 3xl:w-full sm:static sm:w-full top-0 right-0 object-cover w-[50%] h-full sm:h-auto"
            src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data?.images[0]?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          />
        </div>
      </div>
    </section>
  );
};

export default About;
