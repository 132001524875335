import React, { useState } from "react";
import Heading from "../../../ui/shared/Heading";
import Para from "../../../ui/shared/Para";
import FAQ from "../../../elements/shared/FAQ";

const Faqs = ({ data, id }) => {
  const [active, setActive] = useState(-1);

  const handleActive = (i) => {
    setActive(i);
  };

  const onClose = () => {
    setActive(-1);
  };

  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="w-full py-[100px] pb-[50px] sm:py-[30px] flex flex-col items-center 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <div className="flex flex-col sm:flex-col justify-between items-center gap-6 sm:gap-2">
          <Heading
            className="w-[80%] sm:w-full md:w-full text-center sm:text-left"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="w-[75%] md:w-full text-gray-500 sm:w-full text-center sm:text-left"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
        </div>
        <div className="flex flex-col mt-10 sm:mt-6 w-full sm:w-full md:w-full">
          {data.faqs.data.map((faq, index) => (
            <FAQ
              key={faq?.q + index}
              qColor={data.faqs.q.color}
              aColor={data.faqs.a.color}
              q={faq.q}
              a={faq.a}
              handleActive={handleActive}
              active={active === index}
              i={index}
              last={data.faqs.data.length - 1}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
