import React from "react";

const Map = ({ data, id }) => {
  return (
    <div
      id={id}
      className="w-full sm:mb-6"
      dangerouslySetInnerHTML={{ __html: data.link }}
    ></div>
  );
};

export default Map;
