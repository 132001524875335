const TEMPLATE_TYPES = {
  TEMPLATE_1: "TEMPLATE_1",
  TEMPLATE_2: "TEMPLATE_2",
  TEMPLATE_3: "TEMPLATE_3",
  TEMPLATE_4: "TEMPLATE_4"
};

export const WEB_TEMPLATE_SECTIONS = {
  HERO: "hero",
  ABOUT: "about",
  SERVICE: "service",
  BANNER: "banner",
  KEYPOINT: "keyPoint",
  SECONDBANNER: "secondBanner",
  MAP: "map",
  FAQ: "faq"
};

export const THUMBNAIL_TYPE = {
  BACKGROUND: "background", // 1920 x 1080 -> desktop background (for funnel app)
  MOBILE_BACKGROUND: "mobile_background", // 480 x 800 -> mobile background (for funnel app)
  GALLERY_FORM_ASSET: "gallery_form_asset", // 230 x unknown -> gallery form asset
  COMPANY_LOGO: "company_logo", // 580 x unknown -> header logo (for funnel app)
  PARTNER_LOGO: "partner_logo", // 380 x unknown -> footer logo (for funnel app)
  ANSWER_OPTION_ICON: "answer_option_icon", // 214 x unknown -> answer option image
  GALLERY_MODAL_ASSET: "gallery_modal_asset" // 177 x unknown -> gallery modal image
};

export default TEMPLATE_TYPES;
