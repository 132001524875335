import React from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import ServiceCard from "../../../elements/ServiceCard";

const Service = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="flex flex-col w-full items-center py-[50px] md:py-[30px] sm:py-[30px] 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <Heading
          className="w-[80%] text-center sm:w-full md:w-full font-semibold text-[48px] xl:text-[35px] md:text-[35px]"
          style={{ color: data.heading.color }}
        >
          {data.heading.text}
        </Heading>
        <Para
          className="mt-5 text-center w-[70%] md:w-full text-black sm:w-full text-[20px]"
          style={{ color: data.para.color }}
        >
          {data.para.text}
        </Para>
        <div className="flex justify-between flex-wrap sm:flex-col gap-y-8 mt-16 sm:mt-10 w-full">
          {data.services.map((service, index) => (
            <ServiceCard
              key={service?.heading?.text + index}
              service={service}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
