import React from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import KeyPoint from "../../../elements/KeyPoint";

const KeyPoints = ({ data, id }) => {
  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="py-[100px] sm:py-[20px] md:py-[50px] flex flex-col items-center w-full  2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <Heading
          className="text-center w-[80%] sm:w-full font-[600] text-[48px] xl:text-[35px] md:text-[35px]"
          style={{ color: data.heading.color }}
        >
          {data.heading.text}
        </Heading>
        <Para
          className="mt-5 text-center w-[70%] sm:w-full text-[20px] xl:text-[18px] md:text-[18px]"
          style={{ color: data.para.color }}
        >
          {data.para.text}
        </Para>
        <div className="flex gap-28 xl:gap-20 md:gap-16 sm:gap-5 flex-wrap mt-28 sm:mt-10 justify-between">
          {data.keyPoints.map((keypoint, index) => (
            <KeyPoint
              key={keypoint?.heading?.text + index}
              keypoint={keypoint}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyPoints;
