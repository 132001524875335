import React from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  return (
    <Link to={props.link} target="_blank">
      <button
        style={props.style}
        className={`rounded font-medium py-4 px-4 ${props.className}`}
      >
        {props.children}
      </button>
    </Link>
  );
};

export default Button;
