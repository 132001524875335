import React from "react";

const Heading = (props) => {
  return (
    <h1
      style={props.style}
      className={`text-[48px] sm:text-[32px] leading-[58.13px] sm:leading-[38.75px] font-[700] ${props.className}`}
    >
      {props.children}
    </h1>
  );
};

export default Heading;
