import React from "react";
import Heading from "../../ui/shared/Heading";
import Para from "../../ui/shared/Para";
import Button from "../../ui/Button";
import { THUMBNAIL_TYPE } from "../../template.enum";

const Banner = ({ data, id }) => {
  return (
    <section
      id={id}
      className="py-[50px] pt-[120px] sm:py-[50px] md:py-[30px] w-full 2xl:max-w-[1250px] xl:px-[50px] mx-auto md:px-[20px]"
    >
      <div className="shadow-2xl w-full sm:h-auto rounded-xl sm:rounded-none relative">
        <img
          src={`${process.env.REACT_APP_ASSET_CDN_URL}/${data.bg_image?.key}/${THUMBNAIL_TYPE.BACKGROUND}`}
          alt="bg_image"
          className="w-full h-full absolute object-cover rounded-md sm:rounded-none -z-10"
        />
        <div className="h-full w-full py-[50px] sm:py-[20px] sm:px-[20px] bg-[rgba(0,0,0,0.8)] flex flex-col items-center justify-center rounded-lg sm:rounded-none">
          <Heading
            className="w-[70%] sm:w-full text-center"
            style={{ color: data.heading.color }}
          >
            {data.heading.text}
          </Heading>
          <Para
            className="mt-3 text-center w-[80%] sm:w-full"
            style={{ color: data.para.color }}
          >
            {data.para.text}
          </Para>
          <Button
            link={data.btn.link}
            className="rounded-lg mt-8 borders hover:text-black hover:bg-white transition-colors duration-1000 ease-in-out min-w-[250px] sm:w-full text-[17px] sm:text-[14px]"
            style={{
              color: data.btn.color,
              backgroundColor: data.btn.bgColorDisplay
                ? data.btn.bgColor
                : "transparent",
              border: !data.btn.bgColorDisplay && `1px solid ${data.btn.color}`
            }}
          >
            {data.btn.text}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Banner;
