import React from "react";

const AboutImage = (props) => {
  return (
    <div className={`w-[32.5%] md:w-[31.5%] sm:w-full ${props.className}`}>
      <img
        className={`rounded-lg w-full h-[300px] xl:h-[200px] md:h-[200px] sm:h-[200px] object-cover ${props.imageHeight}`}
        alt="about"
        src={props.src}
      />
    </div>
  );
};

export default AboutImage;
