import React from "react";

const Map = ({ data, id }) => {
  return (
    <div
      id={id}
      className="w-full h-[500px] sm:h-auto 2xl:max-w-[1250px] mx-auto rounded mt-10 sm:mt-0 mb-28 sm:mb-0"
      dangerouslySetInnerHTML={{ __html: data.link }}
    ></div>
  );
};

export default Map;
