import React, { useState } from "react";
import Heading from "../../../ui/Heading";
import Para from "../../../ui/Para";
import FAQ from "../../../elements/FAQ";

const Faqs = ({ data, id }) => {
  const [active, setActive] = useState(-1);

  const handleActive = (i) => {
    setActive(i);
  };

  const onClose = () => {
    setActive(-1);
  };

  return (
    <section
      id={id}
      style={{
        backgroundColor: data.bgColorDisplay ? data.bgColor : "transparent"
      }}
    >
      <div className="w-full py-[100px] sm:py-[30px] flex flex-col items-center 2xl:max-w-[1250px] xl:px-[50px] mx-auto sm:px-[20px] md:px-[40px]">
        <Heading
          className="text-center w-[80%] sm:w-full md:w-full font-semibold text-[48px] xl:text-[35px] md:text-[35px]"
          style={{ color: data.heading.color }}
        >
          {data.heading.text}
        </Heading>
        <Para
          className="mt-8 sm:mt-5 text-center w-[65%] md:w-full sm:w-full text-[20px] xl:text-[18px]"
          style={{ color: data.para.color }}
        >
          {data.para.text}
        </Para>
        <div className="flex flex-col mt-10 w-[74%] sm:w-full md:w-full">
          {data.faqs.data.map((faq, index) => (
            <FAQ
              key={faq?.q + index}
              qColor={data.faqs.q.color}
              aColor={data.faqs.a.color}
              q={faq.q}
              a={faq.a}
              handleActive={handleActive}
              active={active === index}
              i={index}
              onClose={onClose}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
